import React from "react";
import Layout from "@/components/layout";
import Footer from "@/components/footer";
import ClientCarousel from "@/components/client-carousel";
import PageHeader from "@/components/page-header";
import MenuContextProvider from "@/context/menu-context";
import HeaderOne from "@/components/header-one";

import ServiceTab1 from "../components/service-tab-1";


const Services2 = () => {
    return (
        <MenuContextProvider>
            <Layout PageTitle="Services2 Page">
                <HeaderOne />
                <PageHeader title="Services2" name="Products" />
                <ServiceTab1 />
                <ClientCarousel />
                <Footer />
            </Layout>
        </MenuContextProvider>
    );
};

export default Services2;